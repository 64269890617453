<template>
  <div v-loading="loading">
    <div style="margin: 10px 30px;">
      <div class="text"> <span>志愿</span>表<el-button type="primary" plain size="small" style="float: right;"
          @click="toggleSelection()">合并导出志愿表</el-button></div>
    </div>

    <div style="margin: 10px;">
      <el-table :data="tableData" style="width: 100%" class="threeTable" :header-cell-style="{
    backgroundColor: '#f4f4f4',
    color: 'black',
    fontSize: '17px',
    textAlign: 'center',
  }" :cell-style="{
    textAlign: 'center',

  }" @selection-change="handleSelectionChange">
        <el-table-column type="selection"></el-table-column>
        <el-table-column label="志愿表">
          <template slot-scope="scope">
            <span>志愿表{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="batchfile" label="批次">
        </el-table-column>
        <el-table-column prop="score" label="成绩">
        </el-table-column>
        <el-table-column prop="subjects" label="科目">
        </el-table-column>
        <el-table-column prop="plansType" label="来源">
        </el-table-column>
        <el-table-column prop="createTime" label="志愿创建时间" width="180">
        </el-table-column>
        <el-table-column prop="cz" label="操作">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small" style="color: blue;">查看</el-button>
            <el-button type="text" size="small" style="color: red;" @click="delID(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- <div style=" margin-left: 25%;">
            <div class="block">
                <pagination :total="queryParams.total" :page.sync="queryParams.pageNum"
                    :limit.sync="queryParams.pageSize" @pagination="onload()" />
            </div>
        </div> -->
  </div>
</template>

<script>
import { queryDetail, removeDetail, queryDetailList } from '../../api/user'
import { downloadZNTableEXcel } from '../../api/aspiration'
export default {

  name: 'comzhx2',
  data() {
    return {

      listtabel: [],
      tableData: [

      ],
      queryParams: {
        total: 56,
        pageNum: 1,
        pageSize: 10
      },
      loading: false
    };
  },
  methods: {
    onload() {
      queryDetail({
        phonenumber: localStorage.getItem("phonenumber")
      }).then(res => {

        this.tableData = res.data
      })
    },
    toggleSelection() {
      if (this.listtabel.length == 0) {
        return this.$message.warning('请选择志愿表')
      }
      let params = {
        userId: localStorage.getItem("phonenumber"),
        volunteerNumbers: this.listtabel.map(item => item.id).join()
      }
      this.loading = true;
      downloadZNTableEXcel(params).then(res => {
        this.loading = false;
        window.location.href = process.env.VUE_APP_BASE_API + '/' + res.data.downUrl;
      })

    },
    handleSelectionChange(val) {
      this.listtabel = val

    },
    handleClick(row) {

      this.$router.push({
        path: '/voluntaryform', query: { id: row.id, zzorzn: 1 }
      })

    },
    delID(row) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        removeDetail({ id: row.id }).then(res => {
          if (res.code == 200) {
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            this.onload()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    }
  },
  mounted() {
    this.onload()
  }

}
</script>

<style lang="scss" scoped>
.text {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
}

.text span {
  border-bottom: 3px solid #74D19D;
}

::deep(.el-button--text) {
  color: red;
}
</style>
